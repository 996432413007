import * as React from 'react'

import { Container, TagButton, TagsWrapper, TagText, Wrapper } from './style'
import PropTypes from 'prop-types'
import { GridContainer } from '@/components/Grid'
import { useRecoilValue } from 'recoil'

import { marketCodeManager } from '@/recoil/marketCode'
import { BANNER_INTERACTION, TAB_SELECTED } from '@/utils/gtmEvents'

const ArticleTags = ({ tags }) => {
  const triggerGtmEvent = (tabName) => {
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
      event: TAB_SELECTED,
      tab_name: tabName
    })
  }
  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <TagsWrapper>
            {tags.map((tag, index) => (
              <TagButton
                onClick={ () => {
                  triggerGtmEvent(tag.text)
                } } key={ `tag-${index}` } href={ `../?filters=${tag.id}&sort=latest` }
              >
                <TagText>{tag.text}</TagText>
              </TagButton>
            ))}
          </TagsWrapper>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

ArticleTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    id: PropTypes.string
  }))
}

export default ArticleTags
