import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  CloseButton,
  Container,
  ContentLeft,
  ContentRight,
  ContentSubtitle,
  ContentText,
  ContentTitle,
  Image,
  ImageCaption,
  ImageWrapper,
  OpenButton, OpenVideoButton,
  Video,
  Viewer,
  ViewerImage,
  ViewerWrapper,
  Wysiwyg,
  Wrapper
} from './style'
import { Actions } from '@/components/Block/Text/style'

import closeButton from '@/images/close-button.svg'
import openButton from '@/images/open-button.svg'
import playButton from '@/images/play-button.svg'
import { VIDEO_COMPLETE, VIDEO_PROGRESS, VIDEO_START } from '@/utils/gtmEvents'
import Player from '@vimeo/player'
const DEFAULT_VIDEO_THRESHOLDS = [
  {
    time: 25,
    reached: false
  },
  {
    time: 50,
    reached: false
  },
  {
    time: 75,
    reached: false
  }
]
const ImageTextPortrait = ({
  display,
  title = '',
  titleTag = 'h3',
  subtitle = '',
  subtitleTag = 'span',
  text = '',
  image = '',
  video = '',
  autoplay = false,
  alt = '',
  caption = '',
  actions = []
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const code = video?.split('/')[3]

  const openOverlay = () => {
    setIsOpen(true)
  }
  const displayVideo = () => {
    setShowVideo(true)
  }

  const closeOverlay = () => {
    setIsOpen(false)
  }
  const [hasBennStarted, setHasBennStarted] = useState(false)
  const iframeRef = useRef(null)
  const [videoThresholds, setVideoThresholds] = useState(DEFAULT_VIDEO_THRESHOLDS)
  const [hasBeenStarted, setHasBeenStarted] = useState(false)
  const triggerGtmEventVideo = (title) => {
    setVideoThresholds(DEFAULT_VIDEO_THRESHOLDS)
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
      event: VIDEO_START,
      video_title: title
    })
  }

  useEffect(() => {
    if (showVideo && video) {
      if (!hasBeenStarted) {
        setHasBeenStarted(true)
        triggerGtmEventVideo(title)
        const player = new Player(iframeRef.current)
        player.on('play', (e) => {
          if (!hasBeenStarted) {
            setHasBeenStarted(true)
            triggerGtmEventVideo(title)
          }
        })
        player.on('timeupdate', (e) => {
          const percent = (e.percent * 100).toFixed(0)
          const flatThresholds = videoThresholds.reduce((acc, curr) => {
            acc.push(curr.time)
            return acc
          }, [])
          flatThresholds.forEach((val, i) => {
            const valReached = percent >= val - 2 && percent < val + 2
            const isAlreadyReached = videoThresholds[i].reached
            if (valReached && !isAlreadyReached) {
              videoThresholds[i].reached = true
              typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
                event: VIDEO_PROGRESS,
                video_title: title,
                video_percent: videoThresholds[i].time
              })
            }
          })
        })
        player.on('ended', (e) => {
          typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
            event: VIDEO_PROGRESS,
            video_title: title,
            video_percent: 100
          })
          typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
            event: VIDEO_COMPLETE,
            video_title: title
          })
        })
      }
    }
  }, [showVideo, video])

  return (
    <Container>
      <Wrapper className={ display && 'alt' }>
        <ContentLeft>
          <ImageWrapper>
            {!showVideo && <Image loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />}
            {(showVideo && video) &&
              (
                <Video
                  ref={ iframeRef }
                  src={ `https://player.vimeo.com/video/${code}?autoplay=1&autopause=0&muted=1` }
                  frameBorder='0'
                  allowFullScreen
                />
              )}
            {!video
              ? (
                <OpenButton onClick={ openOverlay }>
                  <Image src={ openButton } alt='open icon' />
                </OpenButton>
                )
              : (!showVideo
                  ? (
                    <OpenVideoButton onClick={ displayVideo }>
                      <Image src={ playButton } alt='open icon' />
                    </OpenVideoButton>
                    )
                  : <></>
                )}
          </ImageWrapper>
          {!!caption && (<ImageCaption>{caption}</ImageCaption>)}
        </ContentLeft>
        <ContentRight>
          {!!subtitle && (<ContentSubtitle { ...{ as: subtitleTag } }>{subtitle}</ContentSubtitle>)}
          {!!title && (<ContentTitle { ...{ as: titleTag } }>{title}</ContentTitle>)}
          {text && (
            <Wysiwyg html={ text } />
          )}
          {
            !!actions.length && (
              <Actions className={ actions.length > 1 ? 'many' : 'one' }>
                {actions.map((action) => action)}
              </Actions>
            )
          }
        </ContentRight>
      </Wrapper>
      {!video && (
        <Viewer isOpen={ isOpen }>
          <ViewerWrapper>
            <ViewerImage loading='lazy' src={ resolveAssetURL(image) } alt={ alt } />
          </ViewerWrapper>
          <CloseButton onClick={ closeOverlay }>
            <Image src={ closeButton } alt='close icon' />
          </CloseButton>
        </Viewer>
      )}

    </Container>
  )
}

ImageTextPortrait.propTypes = {
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  subtitle: PropTypes.string,
  subtitleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'span']),
  actions: PropTypes.array,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
  video: PropTypes.string,
  autoplay: PropTypes.bool,
  alt: PropTypes.string,
  caption: PropTypes.string,
  display: PropTypes.bool
}

export default ImageTextPortrait
